const freeDomains = new Set([
  "013net.net",
  "126.com",
  "163.com",
  "abv.bg",
  "alice.it",
  "aol.com",
  "arcor.de",
  "att.net",
  "bbox.fr",
  "bellsouth.net",
  "bezeqint.net",
  "bigmir.net",
  "bigpond.com",
  "bigpond.net.au",
  "bk.ru",
  "blueyonder.co.uk",
  "bol.com.br",
  "btinternet.com",
  "centrum.cz",
  "clix.pt",
  "comcast.net",
  "cox.net",
  "daum.net",
  "duck.com",
  "eircom.net",
  "fastmail.com",
  "fastwebnet.it",
  "free.fr",
  "freenet.de",
  "globomail.com",
  "gmail.com",
  "gmx.com",
  "gmx.de",
  "hanmail.net",
  "hotmail.com",
  "i.ua",
  "icloud.com",
  "ig.com.br",
  "inbox.com",
  "inbox.lv",
  "inbox.ru",
  "interia.pl",
  "kabelmail.de",
  "kakao.com",
  "laposte.net",
  "libero.it",
  "list.ru",
  "live.com",
  "mac.com",
  "mail.bg",
  "mail.com",
  "mail.de",
  "mail.ee",
  "mail.kz",
  "mail.lv",
  "mail.pt",
  "mail.ru",
  "mailinator.com",
  "me.com",
  "meta.ua",
  "msn.com",
  "nate.com",
  "naver.com",
  "netcologne.de",
  "netvision.net.il",
  "ntlworld.com",
  "o2.pl",
  "onet.pl",
  "online.de",
  "op.pl",
  "orange.fr",
  "outlook.com",
  "outlook.com.br",
  "post.cz",
  "protonmail.com",
  "qq.com",
  "rambler.ru",
  "rediffmail.com",
  "rocketmail.com",
  "sapo.pt",
  "sbcglobal.net",
  "seznam.cz",
  "sfr.fr",
  "shaw.ca",
  "sky.com",
  "t-online.de",
  "talktalk.net",
  "tele2.it",
  "terra.com.br",
  "tin.it",
  "tiscali.co.uk",
  "tiscali.it",
  "trbvm.com",
  "tutanota.com",
  "ua.fm",
  "ukr.net",
  "ukrpost.ua",
  "unitybox.de",
  "uol.com.br",
  "usa.net",
  "verizon.net",
  "virgilio.it",
  "virgin.net",
  "virginmedia.com",
  "vodafone.it",
  "walla.co.il",
  "wanadoo.fr",
  "web.de",
  "wp.pl",
  "ya.ru",
  "yahoo.ca",
  "yahoo.co.id",
  "yahoo.co.il",
  "yahoo.co.in",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "yahoo.co.th",
  "yahoo.co.uk",
  "yahoo.com",
  "yahoo.com.ar",
  "yahoo.com.au",
  "yahoo.com.br",
  "yahoo.com.mx",
  "yahoo.com.my",
  "yahoo.com.ph",
  "yahoo.com.sg",
  "yahoo.com.vn",
  "yandex.com",
  "ymail.com",
  "zoho.com",
  "zonmail.pt",
])
export default freeDomains
