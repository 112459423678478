import Env from "@utils/env"
import networkService from "services/network/network.service"

async function generateUsingText(
  text: string,
  userId: string,
  isLead: boolean
) {
  const url = `${Env.get("REACT_APP_BASE_URL")}/ai/video/generate-from-text`

  return await networkService.axios
    .post(url, { text, userId, isLead })
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
}

export default generateUsingText
